import useSWR from "swr";
import {
  CreateDocumentCommand,
  CreateDocumentCommandInput,
  CreateDocumentUrlCommand,
  CreateProjectCommand,
  DeleteDocumentCommand,
  DeleteDocumentCommandInput,
  DeleteProjectCommand,
  DeleteProjectCommandInput,
  DocumentMetadataOptionsAllowedValue,
  DownloadDocumentCommand,
  GenerateDocumentMetadataCommand,
  GenerateDocumentMetadataCommandInput,
  GenerateDocumentMetadataResponse,
  GenerateDocumentMetadataStatus,
  GetDocumentMetadataOptionsCommand,
  GetDocumentMetadataResultCommand,
  GetDocumentTitlesCommand,
  GetProjectByIdCommand,
  GetProjectsCommand,
  GetProjectsCommandInput,
  ListDocumentsByProjectIdCommand,
  ListDocumentsByProjectIdCommandOutput,
  SemanticSearchProjectsCommand,
  UDSDocument,
  UpdateDocumentCommand,
  UpdateDocumentCommandInput,
  UpdateProjectCommand,
  UpdateProjectCommandInput,
} from "@amzn/xcm-insights-uds-core-service-client";
import useSWRMutation from "swr/mutation";
import useSWRImmutable from "swr/immutable";
import { useEffect, useState } from "react";
import { objectMap, uploadFile } from "../helpers";
import {
  DocumentMetadataOptions,
  ValueLabel,
  CreateProjectInput,
} from "../client/interfaces";
import { getClient } from "../client/api/client";
import { usePolling } from "./utils";

export function useProjects(
  filter: GetProjectsCommandInput = {},
  suppressed?: boolean,
) {
  const { data, error, isLoading, mutate } = useSWR(
    () => (!suppressed ? ["getProjects", filter] : false),
    async () => {
      const response = await getClient().send(new GetProjectsCommand(filter));
      return response.projects ?? [];
    },
  );
  return {
    data,
    isLoading,
    error,
    mutate,
  };
}

export function useSemanticSearchProjects(searchFilter?: string) {
  const { data, error, isLoading, mutate } = useSWR(
    () => (searchFilter ? ["getSemanticSearchProjects", searchFilter] : false),
    async ([, searchFilter]) => {
      const response = await getClient().send(
        new SemanticSearchProjectsCommand({ searchFilter }),
      );
      return response.projects ?? [];
    },
  );
  return {
    data,
    isLoading,
    error,
    mutate,
  };
}

export function useProject(projectId?: string) {
  const { data, error, isLoading, mutate } = useSWR(
    () => (projectId ? ["getProjectById", projectId] : false),
    async ([, projectId]) => {
      const response = await getClient().send(
        new GetProjectByIdCommand({ projectId }),
      );
      return response.project;
    },
  );
  return {
    data,
    isLoading,
    error,
    mutate,
  };
}

export function useProjectByTitle(projectTitle?: string) {
  const { data, error, isLoading, mutate } = useSWR(
    () => (projectTitle ? ["getProjectByTitle", projectTitle] : false),
    async ([, projectTitle]) => {
      const response = await getClient().send(
        new GetProjectsCommand({ projectTitle }),
      );
      const project = response.projects?.find(
        (project) => project.projectTitle === projectTitle,
      );
      if (!project) {
        throw new Error(`Project with title ${projectTitle} not found`);
      }
      return project;
    },
  );
  return {
    data,
    isLoading,
    error,
    mutate,
  };
}

export function useCreateProject() {
  const { data, error, trigger, isMutating } = useSWRMutation(
    "createProject",
    async (_key, { arg }: { arg: CreateProjectInput }) => {
      const response = await getClient().send(
        new CreateProjectCommand({
          projectId: "00000000000000000000000000", // Dummy project Id
          projectCategory: "XCMI", // Dummy project category
          ...arg,
        }),
      );
      return response;
    },
  );
  return {
    data,
    trigger,
    isMutating,
    error,
  };
}

export function useUpdateProject(projectId?: string) {
  const { data, error, trigger, isMutating } = useSWRMutation(
    () => (projectId ? ["updateProject", projectId] : false),
    async (_key, { arg }: { arg: UpdateProjectCommandInput }) => {
      const response = await getClient().send(new UpdateProjectCommand(arg));
      return response.project;
    },
  );
  return {
    data,
    trigger,
    isMutating,
    error,
  };
}

export function useDeleteProject() {
  const { data, error, trigger, isMutating } = useSWRMutation(
    "deleteProject",
    async (_key, { arg }: { arg: DeleteProjectCommandInput }) => {
      await getClient().send(new DeleteProjectCommand(arg));
    },
  );
  return {
    data,
    trigger,
    isMutating,
    error,
  };
}

/**
 * This hook does not revalidate automatically. Please revalidate using `mutate` manually.
 */
export function useBatchDocuments(projectIds: string[] = [], batchSize = 20) {
  const { data, error, isLoading, mutate } = useSWRImmutable(
    () =>
      projectIds.length > 0
        ? ["batchListDocumentsByProjectId", projectIds]
        : false,
    async ([, projectIds]) => {
      const listDocumentPromises = projectIds.map((projectId) =>
        getClient().send(new ListDocumentsByProjectIdCommand({ projectId })),
      );
      let position = 0;
      const responses: ListDocumentsByProjectIdCommandOutput[] = [];
      while (position < listDocumentPromises.length) {
        const batchedResponses = await Promise.all(
          listDocumentPromises.slice(position, position + batchSize),
        );
        responses.push(...batchedResponses);
        position += batchSize;
      }
      return responses.reduce(
        (allDocuments, response) => [
          ...allDocuments,
          ...response.uDSDocumentList,
        ],
        [] as UDSDocument[],
      );
    },
  );
  return {
    data,
    isLoading,
    error,
    mutate,
  };
}

export function useDocuments(projectId?: string) {
  const { data, error, isLoading, mutate } = useSWR(
    () => (projectId ? ["listDocumentsByProjectId", projectId] : false),
    async ([, projectId]) => {
      const response = await getClient().send(
        new ListDocumentsByProjectIdCommand({ projectId }),
      );
      return response.uDSDocumentList;
    },
  );
  return {
    data,
    isLoading,
    error,
    mutate,
  };
}

export function useDocumentMetadataOptions() {
  const { data, error, isLoading, mutate } = useSWRImmutable(
    "getDocumentMetadataOptions",
    async () => {
      const response = await getClient().send(
        new GetDocumentMetadataOptionsCommand(),
      );
      return objectMap<
        keyof DocumentMetadataOptions,
        DocumentMetadataOptionsAllowedValue,
        ValueLabel[]
      >(response, (options) =>
        options.allowedValues?.map((item) => ({ value: item, label: item })),
      );
    },
  );
  return {
    data,
    isLoading,
    error,
    mutate,
  };
}

export function useUploadDocumentFile() {
  const { data, error, trigger, isMutating } = useSWRMutation(
    "uploadDocumentFile",
    async (
      _,
      {
        arg: { file, documentLink },
      }: { arg: { file: File; documentLink?: string } },
    ) => {
      const response = await getClient().send(
        new CreateDocumentUrlCommand({ documentLink }),
      );
      const headers = {
        "Content-Disposition": `inline; filename=${encodeURIComponent(file.name)}; filename*=utf-8''${encodeURIComponent(file.name)}`,
        "Content-Type": file.type,
      };
      await uploadFile(response.documentLink, file, headers);
      return response.documentLink;
    },
  );
  return {
    data,
    trigger,
    isMutating,
    error,
  };
}

export function useCreateDocument() {
  const { data, error, trigger, isMutating } = useSWRMutation(
    "createDocument",
    async (_key, { arg }: { arg: CreateDocumentCommandInput }) => {
      const response = await getClient().send(new CreateDocumentCommand(arg));
      return response.uDSDocument;
    },
  );
  return {
    data,
    trigger,
    isMutating,
    error,
  };
}

export function useUpdateDocument(documentId?: string) {
  const { data, error, trigger, isMutating } = useSWRMutation(
    () => (documentId ? ["updateDocument", documentId] : false),
    async (_key, { arg }: { arg: UpdateDocumentCommandInput }) => {
      const response = await getClient().send(new UpdateDocumentCommand(arg));
      return response.uDSDocument;
    },
  );
  return {
    data,
    trigger,
    isMutating,
    error,
  };
}

export function useDeleteDocument() {
  const { data, error, trigger, isMutating } = useSWRMutation(
    "deleteDocument",
    async (_key, { arg }: { arg: DeleteDocumentCommandInput }) => {
      await getClient().send(new DeleteDocumentCommand(arg));
    },
  );
  return {
    data,
    trigger,
    isMutating,
    error,
  };
}

export function useDownloadDocumentLink(
  projectId?: string,
  documentId?: string,
  isDownload?: boolean,
) {
  const { data, error, isLoading, mutate } = useSWR(
    () =>
      projectId && documentId
        ? ["downloadDocument", projectId, documentId, isDownload]
        : false,
    async ([, projectId, documentId, isDownload]) => {
      const response = await getClient().send(
        new DownloadDocumentCommand({ projectId, documentId, isDownload }),
      );
      return response.documentLink;
    },
    { revalidateOnFocus: false },
  );
  return {
    data,
    isLoading,
    error,
    mutate,
  };
}

export function useGetDocumentTitles(documentTitle?: string) {
  const { data, error, isLoading, mutate } = useSWR(
    () => (documentTitle ? ["getDocumentTitles", documentTitle] : false),
    async ([, documentTitle]) => {
      const response = await getClient().send(
        new GetDocumentTitlesCommand({ documentTitle }),
      );
      return response.documentTitles;
    },
  );
  return {
    data,
    isLoading,
    error,
    mutate,
  };
}

export function useGenerateDocumentMetadata() {
  const {
    data: metadataId,
    error: generateDocumentMetadataError,
    trigger,
    isMutating: isGeneratingMetadata,
  } = useSWRMutation(
    "generateDocumentMetadata",
    async (_, { arg }: { arg: GenerateDocumentMetadataCommandInput }) => {
      const generateMetadataResponse = await getClient().send(
        new GenerateDocumentMetadataCommand(arg),
      );
      return generateMetadataResponse.metadataId;
    },
  );

  const {
    data: metadataResult,
    error: getMetadataResultError,
    isLoading: isGettingMetadataResult,
    mutate,
  } = useSWRImmutable(
    () => (metadataId ? ["getDocumentMetadataResult", metadataId] : false),
    async ([, metadataId]) => {
      const response = await getClient().send(
        new GetDocumentMetadataResultCommand({ metadataId }),
      );
      return response ?? {};
    },
    {
      shouldRetryOnError: false,
    },
  );

  usePolling(
    () => {
      mutate();
    },
    {
      delayInMillis: 5000,
      retryLimit: 24,
      startPolling: Boolean(metadataId),
      stopPolling:
        generateDocumentMetadataError ||
        getMetadataResultError ||
        metadataResult?.status == GenerateDocumentMetadataStatus.COMPLETED ||
        metadataResult?.status == GenerateDocumentMetadataStatus.ERROR,
      onStopPolling: () => {
        if (
          metadataResult?.status ===
            GenerateDocumentMetadataStatus.GENERATING ||
          metadataResult?.status === GenerateDocumentMetadataStatus.ACCEPTED
        ) {
          setError(
            new Error(
              "Metadata generation timed out. Please try again with a different document.",
            ),
          );
        }
        setIsLoading(false);
      },
      key: metadataId,
    },
  );

  const [data, setData] = useState<GenerateDocumentMetadataResponse>();
  const [error, setError] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setError(generateDocumentMetadataError || getMetadataResultError);
  }, [generateDocumentMetadataError, getMetadataResultError]);

  useEffect(() => {
    if (isGeneratingMetadata || isGettingMetadataResult) {
      setIsLoading(true);
    }
  }, [isGeneratingMetadata, isGettingMetadataResult]);

  useEffect(() => {
    if (metadataResult?.status === GenerateDocumentMetadataStatus.COMPLETED) {
      setData(metadataResult.response);
      setIsLoading(false);
    } else if (
      metadataResult?.status === GenerateDocumentMetadataStatus.ERROR
    ) {
      setError(
        new Error(
          metadataResult.error?.message ??
            "We encountered an error in generating metadata",
        ),
      );
      setIsLoading(false);
    }
  }, [metadataResult]);

  return {
    data,
    trigger,
    isLoading,
    error,
  };
}
