import { PropertyFilterProps } from "@cloudscape-design/components/property-filter";
import { toLocalDate } from "../../helpers";
import TablePropertyDateFilterForm from "../../components/table/TablePropertyDateFilterForm";
import {
  DOCUMENT_PROPERTY_KEY,
  DocumentMetadataName,
} from "./documentConstant";

export const DOCUMENT_TABLE_FILTERING_PROPERTIES: PropertyFilterProps["filteringProperties"] =
  [
    DocumentMetadataName.DOCUMENT_TITLE,
    DocumentMetadataName.DOCUMENT_TYPE,
    DocumentMetadataName.MARKETPLACES,
    DocumentMetadataName.DOCUMENT_YEAR_QUARTER_MONTH,
    DocumentMetadataName.FILENAME,
    DocumentMetadataName.AUTHOR,
    DocumentMetadataName.UPLOAD_DATE,
    DocumentMetadataName.PRIMARY_SUBJECT_AREA,
    DocumentMetadataName.ADDITIONAL_SUBJECT_AREAS,
    DocumentMetadataName.BIG_ROCKS,
    DocumentMetadataName.STATUS,
    DocumentMetadataName.DOCUMENT_CONTENT_TYPES,
    DocumentMetadataName.DOCUMENT_EXPIRATION_DATE,
    DocumentMetadataName.DOCUMENT_CLASSIFICATION,
  ].map((property) => ({
    key: DOCUMENT_PROPERTY_KEY[property],
    operators:
      property === DocumentMetadataName.UPLOAD_DATE ||
      property === DocumentMetadataName.DOCUMENT_EXPIRATION_DATE
        ? [">=", "<="].map((operator) => ({
          operator,
          form: TablePropertyDateFilterForm,
          format: (value) => toLocalDate(parseInt(value)),
          match: "date",
        }))
        : property === DocumentMetadataName.DOCUMENT_TITLE ||
            property === DocumentMetadataName.FILENAME
          ? [":"]
          : property === DocumentMetadataName.MARKETPLACES
            ? [{ operator: "=", tokenType: "enum" }]
            : ["="],
    defaultOperator:
      property === DocumentMetadataName.UPLOAD_DATE ||
      property === DocumentMetadataName.DOCUMENT_EXPIRATION_DATE
        ? ">="
        : property === DocumentMetadataName.DOCUMENT_TITLE ||
            property === DocumentMetadataName.FILENAME
          ? ":"
          : "=",
    propertyLabel: property,
    groupValuesLabel: `${property} values`,
  }));
