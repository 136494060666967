import escapeRegExp from "lodash.escaperegexp";
import { UNKNOWN_ALIAS } from "../data/constants/common";

type ObtainKeysByType<Obj, Type> = {
  [Prop in keyof Obj]: Obj[Prop] extends Type ? Prop : never;
}[keyof Obj];

/**
 * Convert an array of objects into a dictionary of objects by attribute key.
 * @param array - The array of objects to convert.
 * @param attribute - The attribute to use as the key in the dictionary.
 * @returns A dictionary of objects keyed by the specified attribute.
 * @example
 * const array = [{ id: 1, name: 'John' }, { id: 2, name: 'Jane' }];
 * const dict = arrayToDict(array, 'id');
 * // dict = { 1: { id: 1, name: 'John' }, 2: { id: 2, name: 'Jane' } }
 */
export function arrayToDict<
  T extends { [k in string]: any },
  DictKey extends string | number | symbol = string | number | symbol,
  Attr extends ObtainKeysByType<T, DictKey> = ObtainKeysByType<T, DictKey>,
>(array: readonly T[], attribute: Attr) {
  return array.reduce(
    (result, currentItem) => {
      const key = currentItem[attribute] as DictKey;
      result[key] = currentItem;
      return result;
    },
    {} as { [k in DictKey]: T },
  );
}

/**
 * Flatten a nested array of items into a single array of items.
 * Only one level of nesting is supported.
 * @param items items to flatten
 * @param key key to use for nested items
 * @returns flattened array of items
 * @example
 * const items = [{ id: 1 }, { children: [{ id: 2 }, { id: 3 }] }];
 * const flattened = flatten(items, 'children');
 * // flattened = [{ id: 1 }, { id: 2 }, { id: 3 }]
 */
export function flatten<
  S,
  U extends Record<K, ReadonlyArray<S>>,
  K extends string | symbol | number,
>(items: ReadonlyArray<Omit<S, K> | U>, key: K): Omit<S, K>[] {
  const flattened: Omit<S, K>[] = [];
  const itemQueue: (Omit<S, K> | U)[] = [...items];
  while (itemQueue.length > 0) {
    const item = itemQueue.pop();
    if (item === undefined || item === null) {
      break;
    }
    if (Array.isArray((item as U)[key])) {
      itemQueue.push(...(item as U)[key]);
    } else {
      flattened.push(item as Omit<S, K>);
    }
  }
  return flattened;
}

/**
 * Map an object to another object with the same keys.
 * @param object
 * @param callbackFn
 * @returns
 */
export function objectMap<K extends string | number | symbol, V, T>(
  object: { [k in K]?: V },
  callbackFn: (value: V) => T,
): { [k in K]?: T } {
  return Object.entries(object).reduce(
    (result, [key, value]) => {
      result[key as K] = callbackFn(value as V);
      return result;
    },
    {} as { [k in K]: T },
  );
}

export function toPhoneToolLink(alias: string) {
  return `https://phonetool.amazon.com/users/${alias}`;
}

export function extractAlias(email?: string): string {
  return email
    ? email.substring(0, email.indexOf("@")) || UNKNOWN_ALIAS
    : UNKNOWN_ALIAS;
}

export function isCaseInsensitivePartialStringMatch(
  target: string,
  phrase: string,
): boolean {
  return new RegExp(escapeRegExp(phrase.toLowerCase()), "i").test(target);
}

/**
 * Sorts an array based on a sequence of values from another array
 * The order of the items in the sequence is preserved
 * The items not in the sequence are placed at the end in the order they appear in the original array
 * The original array is not modified
 * @param arr the array to sort
 * @param sequence the sequence to sort the array by
 * @returns the sorted array
 * @example
 * const arr = [1, 2, 3, 4, 5];
 * const sequence = [3, 1, 5, 2];
 * const sorted = sortArrayByArraySequence(arr, sequence);
 * // sorted = [3, 1, 5, 2, 4]
 */
export function sortArrayByArraySequence<T>(arr: T[], sequence: T[]): T[] {
  return [...arr].sort((a, b) => {
    const indexA = sequence.indexOf(a);
    const indexB = sequence.indexOf(b);
    if (indexA === -1) return 1;
    if (indexB === -1) return -1;
    return indexA - indexB;
  });
}

/**
 * Encode URI component per RFC3986.
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/encodeURIComponent#encoding_for_rfc3986
 */
export function encodeRFC3986URIComponent(str: string) {
  return encodeURIComponent(str).replace(
    /[!'()*]/g,
    (c) => `%${c.charCodeAt(0).toString(16).toUpperCase()}`,
  );
}
