import React, { useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import {
  Document,
  ValueLabel,
  DocumentStatus,
} from "../../../client/interfaces";
import { MessagesContextType } from "../../../context/MessagingContext";
import {
  useCreateDocument,
  useCreateProject,
  useGenerateDocumentMetadata,
  useUpdateDocument,
  useUploadDocumentFile,
} from "../../../hooks";
import {
  CREATE_PROJECT_ERROR,
  GENERATE_DOCUMENT_METADATA_ERROR,
  UPDATE_DOCUMENT_ERROR,
  UPLOAD_DOCUMENT_FILE_ERROR,
} from "../../../data/constants/errorMessage";
import {
  getObjectKey,
  getUnixTimestampDurationBeforeNow,
  toLocalDate,
  toUnixTimestamp,
} from "../../../helpers";
import CustomModal from "../../../components/common/CustomModal";
import {
  REPLACE_DOCUMENT,
  REPLACE_DOCUMENT_CONTENT,
} from "../../common/documentConstant";
import { DEFAULT_DEBOUNCE_DELAY_MILLIS } from "../../../data/constants/common";
import {
  DocumentFormBoolean,
  DocumentFormSubmitAction,
  DocumentFormSubmitDetail,
  DocumentInputValue,
} from "./documentFormTypes";
import { documentInputValueToCreateDocumentCommandInput } from "./documentFormCreateDocumentInput";

interface UseDocumentFormSubmitProps {
  document?: Document;
  submitAction: DocumentFormSubmitAction;
  onSubmitAction?: () => void;
  onSubmitSuccess?: (detail: DocumentFormSubmitDetail) => void;
  onSubmitError?: () => void;
  addErrorMessage?: MessagesContextType["addErrorMessage"];
  documentInputValue: DocumentInputValue;
  documentFormIsError: React.MutableRefObject<DocumentFormBoolean>;
  documentFormIsModified: React.MutableRefObject<DocumentFormBoolean>;
  newProjectTitleOption?: ValueLabel;
  marketplaceSequence?: string[];
}

export function useDocumentFormSubmit({
  document,
  submitAction,
  onSubmitAction,
  onSubmitSuccess,
  onSubmitError,
  addErrorMessage,
  documentInputValue,
  documentFormIsError,
  documentFormIsModified,
  newProjectTitleOption,
  marketplaceSequence,
}: UseDocumentFormSubmitProps) {
  const {
    trigger: createProject,
    isMutating: isCreatingProject,
    error: createProjectError,
  } = useCreateProject();
  const {
    trigger: uploadDocumentFile,
    isMutating: isUploadingDocumentFile,
    error: uploadDocumentFileError,
  } = useUploadDocumentFile();
  const {
    trigger: createDocument,
    isMutating: isCreatingDocument,
    error: createDocumentError,
  } = useCreateDocument();
  const {
    trigger: updateDocument,
    isMutating: isUpdatingDocument,
    error: updateDocumentError,
  } = useUpdateDocument(document?.documentId);
  const {
    data: generatedDocumentMetadata,
    trigger: generateDocumentMetadata,
    isLoading: isGeneratingDocumentMetadata,
    error: generateDocumentMetadataError,
  } = useGenerateDocumentMetadata();

  const [metadataAlreadyGenerated, setMetadataAlreadyGenerated] =
    useState(false);
  // Debounce `isUploadingDocumentFile` and `isGeneratingDocumentMetadata` to prevent a
  // gap between the these two state transitions
  const [
    debouncedIsGeneratingDocumentMetadata,
    setDebouncedIsGeneratingDocumentMetadata,
  ] = useState<boolean | undefined>(false);
  const debounceIsGeneratingDocumentMetadata = useDebouncedCallback(
    (value?: boolean) => setDebouncedIsGeneratingDocumentMetadata(value),
    DEFAULT_DEBOUNCE_DELAY_MILLIS,
  );

  useEffect(() => {
    debounceIsGeneratingDocumentMetadata(
      isUploadingDocumentFile || isGeneratingDocumentMetadata,
    );
  }, [isUploadingDocumentFile, isGeneratingDocumentMetadata]);

  // Document replacement confirmation modal
  const [replaceDocumentModalVisible, setReplaceDocumentModalVisible] =
    useState(false);

  useEffect(() => {
    setMetadataAlreadyGenerated(false);
  }, [documentInputValue.fileName]);

  useEffect(() => {
    if (generateDocumentMetadataError) {
      setMetadataAlreadyGenerated(false);
    }
  }, [generateDocumentMetadataError]);

  useEffect(() => {
    if (createProjectError) {
      addErrorMessage?.(createProjectError, CREATE_PROJECT_ERROR);
    }
    if (uploadDocumentFileError) {
      addErrorMessage?.(uploadDocumentFileError, UPLOAD_DOCUMENT_FILE_ERROR);
    }
    if (createDocumentError) {
      addErrorMessage?.(createDocumentError, CREATE_PROJECT_ERROR);
    }
    if (updateDocumentError) {
      addErrorMessage?.(updateDocumentError, UPDATE_DOCUMENT_ERROR);
    }
    if (generateDocumentMetadataError) {
      addErrorMessage?.(
        generateDocumentMetadataError,
        GENERATE_DOCUMENT_METADATA_ERROR,
      );
    }
  }, [
    createProjectError,
    uploadDocumentFileError,
    createDocumentError,
    updateDocumentError,
    generateDocumentMetadataError,
  ]);

  async function submit() {
    try {
      // Handle new project creation

      // projectId is either projectId for existing project or projectTitle for new project
      let projectId = documentInputValue.projectId;

      if (projectId === newProjectTitleOption?.value) {
        // if new project is selected
        const createdProject = await createProject({
          projectTitle: projectId,
          projectDescription: documentInputValue.projectDescription,
        });
        projectId = createdProject.projectId;
      }

      // Handle document create or update
      let newOrUpdatedDocument: Document | undefined = document;
      let isModified = true;

      switch (submitAction) {
      case DocumentFormSubmitAction.CREATE:
      case DocumentFormSubmitAction.DUPLICATE:
        {
          const documentObjectKey = getObjectKey(
            await uploadDocumentFile({
              file: documentInputValue.fileName[0],
            }),
          );
          newOrUpdatedDocument = await createDocument(
            documentInputValueToCreateDocumentCommandInput({
              documentInputValue: { ...documentInputValue, projectId },
              documentObjectKey,
              marketplaceSequence,
            }),
          );
        }
        break;

      case DocumentFormSubmitAction.EDIT:
        isModified = Object.values(documentFormIsModified.current).some(
          (value) => value,
        );
        if (document && isModified) {
          const documentObjectKey = documentFormIsModified.current.fileName
            ? getObjectKey(
              await uploadDocumentFile({
                file: documentInputValue.fileName[0],
                documentLink: document.documentLink,
              }),
            )
            : document.documentLink;
          const fileSizeInBytesToPersist = documentFormIsModified.current
            .fileName
            ? undefined
            : document.fileSizeInBytes;
            // If status is modified, EXPIRED and expiration date is greater than today,
            // then set the expirate date to today
          const documentExpirationDate =
              documentFormIsModified.current.status &&
              documentInputValue.status === DocumentStatus.EXPIRED &&
              (toUnixTimestamp(documentInputValue.documentExpirationDate) ??
                Number.MAX_SAFE_INTEGER) > getUnixTimestampDurationBeforeNow()
                ? toLocalDate(getUnixTimestampDurationBeforeNow())
                : documentInputValue.documentExpirationDate;
          newOrUpdatedDocument = await updateDocument({
            ...documentInputValueToCreateDocumentCommandInput({
              documentInputValue: {
                ...documentInputValue,
                projectId,
                documentExpirationDate,
              },
              documentObjectKey,
              fileSizeInBytes: fileSizeInBytesToPersist,
              marketplaceSequence,
            }),
            documentId: document.documentId,
          });
        }
      }

      if (!newOrUpdatedDocument) {
        throw new Error("Document create/update failed!");
      }

      onSubmitSuccess?.({
        document: newOrUpdatedDocument,
        noChanges: !isModified,
      });
    } catch (e) {
      // Error handled in flashbar messages
    }
  }

  function handleSubmit() {
    onSubmitAction?.();

    if (Object.values(documentFormIsError.current).some((value) => value)) {
      onSubmitError?.();
    } else if (
      submitAction === DocumentFormSubmitAction.EDIT &&
      documentFormIsModified.current.fileName
    ) {
      setReplaceDocumentModalVisible(true);
    } else {
      submit();
    }
  }

  async function handleGenerateDocumentMetadata() {
    try {
      if (documentFormIsModified.current.fileName) {
        const documentObjectKey = getObjectKey(
          await uploadDocumentFile({
            file: documentInputValue.fileName[0],
          }),
        );
        await generateDocumentMetadata({ documentLink: documentObjectKey });
        setMetadataAlreadyGenerated(true);
      }
    } catch (e) {
      // Error handled in flashbar messages
    }
  }

  const replaceDocumentModal = (
    <CustomModal
      data-testid="document-form-replace-document-modal"
      visible={replaceDocumentModalVisible}
      setVisible={setReplaceDocumentModalVisible}
      header={REPLACE_DOCUMENT}
      primaryActionButtonText="Proceed"
      onPrimaryAction={() => {
        submit();
        setReplaceDocumentModalVisible(false);
      }}
    >
      {REPLACE_DOCUMENT_CONTENT(document?.fileName)}
    </CustomModal>
  );

  return {
    handleSubmit,
    isSubmitting:
      isCreatingProject ||
      isUploadingDocumentFile ||
      isCreatingDocument ||
      isUpdatingDocument,
    replaceDocumentModal,
    handleGenerateDocumentMetadata,
    isGeneratingDocumentMetadata: debouncedIsGeneratingDocumentMetadata,
    generatedDocumentMetadata,
    metadataAlreadyGenerated,
  };
}
