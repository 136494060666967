import React, { FC, useContext, useEffect, useRef, useState } from "react";
import SplitPanel from "@cloudscape-design/components/split-panel";
import { CollectionPreferencesProps } from "@cloudscape-design/components/collection-preferences";
import ButtonDropdown, {
  ButtonDropdownProps,
} from "@cloudscape-design/components/button-dropdown";
import Link from "@cloudscape-design/components/link";
import {
  useSplitPanel,
  useProjects,
  useLocalStoragePreferences,
  useSemanticSearchProjects,
  useSettingsWithAuthConfigured,
} from "../../hooks";
import CustomAppLayout from "../../components/layout/CustomAppLayout";
import { Project, UserRole } from "../../client/interfaces";
import TableHeader from "../../components/header/TableHeader";
import { AuthContext } from "../../context/AuthContext";
import ServerClientHybridCollectionTable from "../../components/table/ServerClientHybridCollectionTable";
import { MessageContext } from "../../context/MessagingContext";
import { GET_PROJECTS_ERROR } from "../../data/constants/errorMessage";
import { useExportDocumentMetadata } from "../common/documentMetadataExport";
import {
  PROJECT_PROPERTY_KEY,
  ProjectMetadataName,
} from "../common/projectConstant";
import { PROJECT_TABLE_COLUMN_DEFINITIONS } from "../common/projectTableColumnDefinition";
import { useTableColumnWidth } from "../common/tableColumnWidth";
import { useDocumentDelete } from "../common/documentDelete";
import { useDashboardSplitPanelElement } from "../panel/dashboardSplitPanelElement";
import { useDocumentDownload } from "../common/documentDownload";
import { CopyLink } from "../common/shareLinkCopy";
import { useProjectDelete } from "../common/projectDelete";
import {
  ProjectDashboardAction,
  ProjectDashboardCreateOption,
} from "./projectDashboard/projectDashboardTypes";
import { getProjectDashboardContextMenuItems } from "./projectDashboard/projectDashboardContextMenu";
import {
  PROJECT_TABLE_PREFERENCES,
  PROJECT_TABLE_PREFERENCES_CONTENT_DISPLAY_OPTIONS,
} from "./projectDashboard/projectDashboardTableConfig";
import {
  ProjectDashboardSemanticSearchToggle,
  ProjectDashboardTableHelpPanel,
  useProjectDashboardFilter,
} from "./projectDashboard";

const PROJECT_TABLE_PREFERENCES_KEY = "projectTablePreferences";
const PROJECT_TABLE_COLUMN_WIDTHS_KEY = "projectTableColumnWidths";
const PROJECT_TABLE_SPLIT_PANEL_SIZE_KEY = "documentTableSplitPanelSize";

const ProjectDashboard: FC = () => {
  const { preferences, setPreferences } =
    useLocalStoragePreferences<CollectionPreferencesProps.Preferences>(
      PROJECT_TABLE_PREFERENCES_KEY,
    );
  const { widths, onColumnWidthsChange, tableKeyForWidths } =
    useTableColumnWidth(PROJECT_TABLE_COLUMN_WIDTHS_KEY);
  const [toolsOpen, setToolsOpen] = useState(false);

  const { alias, isEditor } = useContext(AuthContext);
  const { addErrorMessage, addMessage } = useContext(MessageContext);
  const { data: settings } = useSettingsWithAuthConfigured();

  const {
    handleFilterChange,
    query,
    setQuery,
    getProjectsFilter,
    filteringPlaceholder,
    filteringProperties,
    filteringOptions,
    handleOnFilteringLoadItems,
    filteringLoading,
    semanticSearchEnabled,
    handleSemanticSearchToggle,
    semanticSearchFilter,
    copyUrlDisplayText,
  } = useProjectDashboardFilter({
    alias,
    isEditor,
    addErrorMessage,
    FEATURE_FLAGS_enableSemanticSearch:
      settings?.FEATURE_FLAGS_enableSemanticSearch,
  });

  const {
    data: columnarFilteredProjects,
    isLoading: isLoadingColumnarFilteredProjects,
    mutate: reloadColumnarFilterProjects,
    error: getProjectsError,
  } = useProjects(
    getProjectsFilter,
    semanticSearchEnabled && Boolean(semanticSearchFilter?.searchFilter),
  );

  const {
    data: semanticSearchProjects,
    isLoading: isLoadingSemanticSearchProjects,
    mutate: reloadSemanticSearchProjects,
    error: semanticSearchProjectsError,
  } = useSemanticSearchProjects(semanticSearchFilter?.searchFilter);

  const projects = columnarFilteredProjects || semanticSearchProjects;
  const isLoadingProjects =
    isLoadingColumnarFilteredProjects || isLoadingSemanticSearchProjects;
  const reloadProjects = () => {
    reloadColumnarFilterProjects();
    reloadSemanticSearchProjects();
  };

  // Download document
  const { downloadDocument } = useDocumentDownload({
    addErrorMessage,
  });

  // Export document metadata
  const { exportDocumentMetadata, isExporting, refreshBatchDocuments } =
    useExportDocumentMetadata({
      addErrorMessage,
    });

  // Table selection states
  const [tableSelectedItem, setTableSelectedItem] = useState<Project[]>([]);

  // Delete document states
  const { deleteDocument, documentDeleteConfirmationModal } = useDocumentDelete(
    {
      modalDataTestId: "project-dashboard-delete-document-modal",
      addMessage,
      addErrorMessage,
      onDocumentChange: () => closeSplitPanel(),
    },
  );

  // Delete project states
  const { deleteProject, projectDeleteConfirmationModal } = useProjectDelete({
    modalDataTestId: "project-dashboard-delete-project-modal",
    addMessage,
    addErrorMessage,
    onProjectChange: (project) => {
      if (
        openedProjects?.length === 1 &&
        project.projectId === openedProjects[0].projectId
      ) {
        closeSplitPanel();
      }
      if (project.projectId === tableSelectedItem[0]?.projectId) {
        setTableSelectedItem([]);
      }
      reloadProjects();
    },
  });

  // Split panel states
  const divRef = useRef<HTMLDivElement>(null);
  const {
    splitPanelSize,
    onSplitPanelResize,
    splitPanelOpen,
    setSplitPanelOpen,
    onSplitPanelToggle,
  } = useSplitPanel(PROJECT_TABLE_SPLIT_PANEL_SIZE_KEY);
  const {
    splitPanelElement,
    splitPanelHeader,
    closeSplitPanel,
    createDocument,
    createProject,
    openedProjects,
    editProject,
    viewProjectDetail,
  } = useDashboardSplitPanelElement({
    setSplitPanelOpen,
    dashboardDivRef: divRef,
    splitPanelSelector: "[data-project-dashboard-selector=\"split-panel\"]",
    onDocumentChange: () => reloadProjects(),
    onProjectChange: () => reloadProjects(),
    handleDownloadDocument: ({ document }) =>
      downloadDocument(document.projectId, document.documentId, true),
    handleDeleteDocument: ({ document }) => deleteDocument(document),
    handleExportDocumentMetadata: ({ project, document }) =>
      exportDocumentMetadata(project, [document]),
    handleExportDocumentMetadataByProject: ({ project, documents }) =>
      documents
        ? exportDocumentMetadata(project, documents)
        : exportDocumentMetadata([project]),
    handleDeleteProject: deleteProject,
  });

  useEffect(() => {
    if (!openedProjects) {
      setTableSelectedItem([]);
    }
  }, [openedProjects]);

  useEffect(() => {
    if (openedProjects && tableSelectedItem.length === 0) {
      closeSplitPanel();
    }
    if (tableSelectedItem.length > 0) {
      viewProjectDetail({
        projects: tableSelectedItem as [Project, ...Project[]],
      });
    }
  }, [tableSelectedItem]);

  useEffect(() => {
    if (getProjectsError || semanticSearchProjectsError) {
      addErrorMessage?.(
        getProjectsError || semanticSearchProjectsError,
        GET_PROJECTS_ERROR,
      );
    }
  }, [getProjectsError, semanticSearchProjectsError]);

  function handleTableHeaderActionItemClick(
    event: CustomEvent<ButtonDropdownProps.ItemClickDetails>,
  ) {
    const { id } = event.detail;
    switch (id) {
    case ProjectDashboardAction.EDIT:
      tableSelectedItem.length === 1 &&
          editProject({ project: tableSelectedItem[0] });
      break;
    case ProjectDashboardAction.DELETE:
      deleteProject({ project: tableSelectedItem[0] });
      break;
    case ProjectDashboardAction.EXPORT:
      exportDocumentMetadata(tableSelectedItem);
      break;
    case ProjectDashboardAction.SHARE_SELECTED:
      tableSelectedItem.length === 1 &&
          CopyLink.copyProjectLink(tableSelectedItem[0]);
      break;
    }
  }

  function handleTableHeaderCreateOptionItemClick(
    event: CustomEvent<ButtonDropdownProps.ItemClickDetails>,
  ) {
    const { id } = event.detail;
    switch (id) {
    case ProjectDashboardCreateOption.PROJECT:
      createProject();
      break;
    case ProjectDashboardCreateOption.DOCUMENT:
      createDocument();
      break;
    }
  }

  const headerButtons = [
    <ButtonDropdown
      key="actions"
      data-testid="project-table-header-actions"
      items={getProjectDashboardContextMenuItems({
        isExporting,
        numSelectedItems: tableSelectedItem.length,
        isEditor,
      })}
      disabled={tableSelectedItem.length === 0}
      disabledReason="No projects selected"
      onItemClick={handleTableHeaderActionItemClick}
      loading={isExporting}
    >
      Actions
    </ButtonDropdown>,
    <ButtonDropdown
      key="upload"
      data-testid="handle-create-project-or-document"
      data-roles={`${UserRole.ADMIN} ${UserRole.AUTHOR}`}
      items={Object.values(ProjectDashboardCreateOption).map((option) => ({
        id: option,
        text: option,
      }))}
      onItemClick={handleTableHeaderCreateOptionItemClick}
      variant="primary"
    >
      Create project or document
    </ButtonDropdown>,
  ];

  return (
    <div data-testid="project-dashboard" ref={divRef}>
      <CustomAppLayout
        toolsOpen={toolsOpen}
        tools={<ProjectDashboardTableHelpPanel />}
        onToolsChange={({ detail }) => setToolsOpen(detail.open)}
        content={
          <ServerClientHybridCollectionTable
            key={tableKeyForWidths}
            copyUrlProps={{
              textToCopy: CopyLink.getCurrentUrl(),
              displayText: copyUrlDisplayText,
            }}
            variant="full-page"
            selectionType="multi"
            selectedItems={tableSelectedItem}
            onSelectionChange={({ detail }) =>
              setTableSelectedItem(detail.selectedItems)
            }
            withClientSidePagination={true}
            withClientSideSorting={true}
            sortingColumn={{
              sortingField:
                PROJECT_PROPERTY_KEY[ProjectMetadataName.LAST_UPLOAD_DATE],
            }}
            sortingDescending={true}
            filteringPlaceholder={filteringPlaceholder}
            items={projects ?? []}
            itemMatchCount={projects?.length}
            loading={isLoadingProjects}
            preferences={preferences ?? PROJECT_TABLE_PREFERENCES}
            onPreferencesConfirm={(detail) => setPreferences(detail)}
            columnDefinitions={PROJECT_TABLE_COLUMN_DEFINITIONS({
              projectFilterQueryString: "",
              widths,
              selectedProject:
                openedProjects?.length === 1 ? openedProjects[0] : undefined,
            })}
            onColumnWidthsChange={onColumnWidthsChange}
            contentDisplayOptions={
              PROJECT_TABLE_PREFERENCES_CONTENT_DISPLAY_OPTIONS
            }
            customControl={
              settings?.FEATURE_FLAGS_enableSemanticSearch === "true" ? (
                <ProjectDashboardSemanticSearchToggle
                  isSemanticSearch={semanticSearchEnabled}
                  onSemanticSearchToggle={handleSemanticSearchToggle}
                />
              ) : undefined
            }
            reverseCustomControlDisplayOrder={true}
            filteringProperties={filteringProperties}
            filteringOptions={filteringOptions(projects)}
            filteringLoading={filteringLoading}
            onFilteringLoadItems={handleOnFilteringLoadItems}
            onFilterChange={(query) =>
              handleFilterChange(query, semanticSearchEnabled)
            }
            queryOverride={query}
            setQueryOverride={setQuery}
            empty="No projects"
            trackBy={(item) => item.projectId}
            header={
              <TableHeader
                showRefreshButton={true}
                refreshButtonOnClick={() => {
                  reloadProjects();
                  refreshBatchDocuments();
                }}
                buttons={headerButtons}
                info={
                  settings?.FEATURE_FLAGS_enableSemanticSearch === "true" ? (
                    <Link
                      data-testid="project-dashboard-table-info-link"
                      variant="info"
                      onFollow={() => setToolsOpen(true)}
                    >
                      Info
                    </Link>
                  ) : undefined
                }
              >
                Projects
              </TableHeader>
            }
          />
        }
        splitPanel={
          <SplitPanel
            data-project-dashboard-selector="split-panel"
            header={splitPanelHeader}
            closeBehavior="hide"
            hidePreferencesButton={true}
          >
            {splitPanelElement}
          </SplitPanel>
        }
        splitPanelOpen={splitPanelOpen}
        splitPanelPreferences={{ position: "side" }}
        splitPanelSize={splitPanelSize}
        onSplitPanelResize={onSplitPanelResize}
        onSplitPanelToggle={(e) => {
          onSplitPanelToggle(e);
          closeSplitPanel();
        }}
      />
      {documentDeleteConfirmationModal}
      {projectDeleteConfirmationModal}
    </div>
  );
};

export default ProjectDashboard;
